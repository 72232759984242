import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        siteDescription: description
        siteTitle: title
        image
        author
      }
    }
  }
`

function SEO({ children, title, location }) {
  const { site } = useStaticQuery(query)
  const { siteDesc, siteTitle, image } = site.siteMetadata
  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | ${siteTitle}`}>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={siteDesc} />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta name="image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta propery="og:site_name" content={siteTitle} key="ogsitename" />
      <meta property="og:description" content={siteDesc} key="ogdesc" />
      {children}
    </Helmet>
  )
}

export default SEO
